import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Headbar from "../app/app-components/headbar"
import _ from "lodash"
import Markdown from "react-markdown"
import { JSONPath } from "jsonpath-plus"
import {
  ClockIcon,
  ShoppingCartIcon,
  QuestionMarkCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/solid"
import { motion } from "framer-motion"
import { myAnimations } from "../context/framer-animations"

const Instruction = props => {
  // console.log(props)
  const exam = props.pageContext.exam[0]
  const paper = props.pageContext.paper[0] || {}
  const template = paper?.template || []
  const quizIdentifier = JSONPath("$..sectionId", template) || []
  const paperId = { id: paper?.paperId, name: paper?.paperName }

  return (
    <div className="h-screen py-5 overflow-y-scroll bg-gray-200 hideScrollbar">
      <Headbar isBackButton={true} title="Instructions" />
      <motion.div {...myAnimations.fadeIn} className="pt-8 pb-24 m-5 ">
        <QuizTotalInfoCard
          section={template?.sections}
          title={template?.templateName}
          exam={exam}
        />
        <div className="pt-8 pb-4 font-base">Exam Pattern</div>
        {template?.sections.map((section, idx) => (
          <QuizSectionalInfoCard
            key={section?.sectionId}
            section={section}
            quizIdentifier={quizIdentifier}
            paperId={paperId}
            isSubjectWise={props.isSubjectWise}
          />
        ))}
        <div className="hidden pt-8 pb-4 font-base">Instructions</div>
        <div className="hidden p-4 bg-white shadow rounded-xl">
          <Markdown children={template.instructions} />
        </div>
      </motion.div>
      {!props.isSubjectWise && (
        <motion.div
          {...myAnimations.slideleft}
          className="fixed bottom-0 left-0 right-0 z-50 h-20 p-2 mt-20 bg-white rounded-t-xl "
        >
          <Link
            to={`/paperquiz`}
            state={{
              identifier: quizIdentifier,
              quizTime: template.quizTotalTime,
              paperId: paperId,
            }}
            className="w-full text-xl py-7 btn font-base"
          >
            Start
          </Link>
        </motion.div>
      )}
    </div>
  )
}

function QuizSectionalInfoCard({
  section,
  quizIdentifier,
  paperId,
  isSubjectWise,
}) {
  return (
    <div className="flex flex-col p-4 mb-2 bg-white border-b shadow rounded-xl">
      <div className="flex justify-start mb-2">
        <p className="font-base ">{section?.sectionName}</p>
      </div>
      <div className="flex justify-between ">
        <div className="flex flex-col space-y-1 text-xs">
          <div className="flex">
            <QuestionMarkCircleIcon className="w-4 h-4 mr-2" />
            <p>{section?.sectionTotalQuestions} Q</p>
          </div>
          <div className="flex">
            <ShoppingCartIcon className="w-4 h-4 mr-2" />
            <p>{section?.sectionTotalMarks} Marks</p>
          </div>
          <div className="flex">
            <ClockIcon className="w-4 h-4 mr-2" />
            <p>{section?.sectionTime} min</p>
          </div>
        </div>
        <div>
          {isSubjectWise && (
            <Link
              to={`/paperquiz`}
              state={{
                identifier: quizIdentifier.filter(
                  id => id === section?.sectionId
                ),
                quizTime: section?.sectionTime,
                paperId: paperId,
              }}
              className="px-8 btn"
            >
              Start
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export function QuizTotalInfoCard({ title, sections, exam }) {
  // let totalTime = sections?.reduce((total, section) => {
  //   return total + section?.sectionTime
  // })
  //console.log(totalTime)
  return (
    <div className="flex flex-col p-4 bg-white shadow rounded-xl ">
      <div className="flex items-center justify-center">
        <div className="justify-center w-20 h-20 rounded-full left-1/2">
          {exam?.icon && (
            <GatsbyImage
              image={exam.icon.localFile.childImageSharp.gatsbyImageData}
              alt={exam.examId}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <p className="text-xl font-base ">{title}</p>
      </div>
      <div className="flex justify-around hidden space-y-1 text-sm">
        <div className="flex items-center justify-start">
          <QuestionMarkCircleIcon className="w-6 h-6 mr-2" />
          <p>15Q</p>
        </div>
        <div className="flex items-center justify-start">
          <ShoppingCartIcon className="w-6 h-6 mr-2" />
          <p>35 Marks</p>
        </div>
        <div className="flex items-center justify-start">
          <MinusCircleIcon className="w-6 h-6 mr-2" />
          <p>1/4</p>
        </div>
        <div className="flex items-center justify-start">
          <ClockIcon className="w-6 h-6 mr-2" />
          <p>30 min</p>
        </div>
      </div>
    </div>
  )
}

export default Instruction

import React from "react"
import Instruction from "../components/quiz/instructions"
import GlobalState from "../components/context/GlobalState"

const Instructions = props => {
  // console.log(props)
  if (!props?.location?.state?.paperId) {
    return <p>Invalid Access</p>
  }
  return (
    <GlobalState>
      <Instruction paperId={props?.location?.state?.paperId} />
    </GlobalState>
  )
}

export default Instructions
